import React, { useContext, Fragment } from "react";
import { observer } from "mobx-react-lite";
import { Redirect } from "react-router-dom";

// import useQuery from "../util/useQuery";
import { userContext } from "../store";
import useCheckCode from "../util/useCheckCode";

const Auth = observer(() => {
  const store = useContext(userContext);
  const success = useCheckCode();
  console.log({ success });
  return (
    <Fragment>
      {store.email}
      {success === false && <h1>Something went wrong</h1>}
      {success === true && <Redirect to="/" />}
      {success === null && <h1>Authenticating...</h1>}
    </Fragment>
  );
});

export default Auth;
