import { BASE_URL } from "../const";

export default async function appendToSheet(accessToken, entry) {
  console.log("[appendToSheet] entry", entry);
  try {
    const response = await fetch(`${BASE_URL}sheets/append`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ entry })
    });
    const data = await response.json();
    console.log("data", data);
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}
