import React, { useContext } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  makeStyles
} from "@material-ui/core";
import { observer } from "mobx-react-lite";
import { userContext } from "../store";
import useLoginUrl from "../util/useLoginUrl";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      margin: 0
    },
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none"
    }
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: "wrap"
  },
  toolbarTitle: {
    flexGrow: 1
  }
}));

const Header = observer(() => {
  const store = useContext(userContext);
  const classes = useStyles();
  const url = useLoginUrl();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
        >
          Sheets Logbook
        </Typography>
        {store.loggedIn ? (
          <Button
            onClick={store.clearStore}
            color="primary"
            variant="outlined"
            className={classes.link}
          >
            Logout
          </Button>
        ) : (
          <Button
            href={url}
            color="primary"
            variant="outlined"
            className={classes.link}
          >
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
});
export default Header;
