export const CLIENT_ID =
  "269216902314-mtte6hsjbnb19j1rovfhe28ir2ugm8pb.apps.googleusercontent.com";

export const CLIENT_SECRET = "mstDAoIuq5PukGae1txxNk7O";

export const RANGE = "A:B";

// export const BASE_URL = "https://w6thc.sse.codesandbox.io/";

export const BASE_URL = "https://us-central1-sheets-logbook.cloudfunctions.net/sheetsapi/";
