import { useContext, useState, useEffect } from "react";
import useQuery from "./useQuery";
import { userContext } from "../store";
import { BASE_URL } from "../const";

export default function useCheckCode() {
  const query = useQuery();
  const code = query.get("code");
  const store = useContext(userContext);
  const [success, setSuccess] = useState(null);
  useEffect(() => {
    async function checkCode() {
      console.log("[checkCode]", code);
      try {
        const response = await fetch(`${BASE_URL}auth/tokens`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({ code })
        });
        const data = await response.json();
        console.log("data", data);
        if (data.user) {
          store.setUser(data.user);
          setSuccess(true);
        } else {
          setSuccess(false);
        }
      } catch (error) {
        console.error(error);
        setSuccess(false);
      }
    }
    if (code) {
      checkCode();
    }
  }, [code, store]);
  return success;
}
