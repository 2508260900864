import React, { Fragment, useContext } from "react";
import { observer } from "mobx-react-lite";
import { Container, Typography, Button } from "@material-ui/core";
import { userContext } from "../store";
import List from "./List";
import createSpreadSheet from "../util/createSpreadSheet";

const Home = observer(() => {
  const store = useContext(userContext);

  async function createSpreadsheet() {
    const newSpreadsheetId = await createSpreadSheet(store.user.accessToken);
    // Update Store
    store.setUser({ ...store.user, spreadsheetId: newSpreadsheetId });
  }
  console.log({ spreadsheetId: store.user.spreadsheetId });

  if (!store.user.email) {
    return (
      <Container>
        <Typography gutterBottom>Please login to continue</Typography>
      </Container>
    );
  }
  return (
    <Fragment>
      {store.user.email && (
        <Container>
          <Typography gutterBottom>
            Welcome back <b>{store.user.email}</b>
          </Typography>
        </Container>
      )}
      {!store.user.spreadsheetId && (
        <Button onClick={createSpreadsheet}>Create spreadsheet</Button>
      )}
      {store.user.spreadsheetId && (
        <List
          spreadsheetId={store.user.spreadsheetId}
          accessToken={store.user.accessToken}
        />
      )}
    </Fragment>
  );
});

export default Home;
