import { useState, useEffect } from "react";
import { BASE_URL } from "../const";

export default function useLoginUrl() {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    (async function getUrl() {
      try {
        const response = await fetch(`${BASE_URL}auth/?host=${window.location.host}`);
        const { url } = await response.json();
        setUrl(url);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);
  return url;
}
