import { useEffect, useState } from "react";
import { BASE_URL } from "../const";

export default function useReadSheet(accessToken, shouldRefresh) {
  const [values, setValues] = useState(null);
  useEffect(() => {
    (async function readSheet() {
      console.log("[useReadSheet] readSheet", accessToken);
      try {
        const response = await fetch(`${BASE_URL}sheets/read`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`
          }
          // body: JSON.stringify({ code })
        });
        const { data, message } = await response.json();
        const { values: _values } = data || {};
        console.log({ data, _values, message });
        if (_values) {
          setValues(_values);
        } else {
          setValues(null);
        }
      } catch (error) {
        console.error(error);
        setValues(null);
      }
    })();
  }, [accessToken, shouldRefresh]);
  return values;
}
