import React, { createContext } from "react";
import { useLocalStore } from "mobx-react-lite";

export const userContext = createContext();

export const UserProvider = ({ children }) => {
  const store = useLocalStore(() => ({
    user: JSON.parse(localStorage.getItem("user")) || {},
    setUser(user) {
      console.log("setUser", user);
      store.user = user;
      localStorage.setItem("user", JSON.stringify(user));
    },
    clearStore() {
      store.user = {};
      localStorage.removeItem("user");
    },
    get loggedIn() {
      return !!(store.user && store.user.accessToken);
    }
  }));
  return <userContext.Provider value={store}>{children}</userContext.Provider>;
};
