import { BASE_URL } from "../const";

export default async function createSpreadSheet(accessToken) {
  console.log("[createSpreadSheet] accessToken", accessToken);
  try {
    const response = await fetch(`${BASE_URL}sheets/create`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });
    const { error, spreadsheetId } = await response.json();
    console.log("data", { error, spreadsheetId });
    return error === false ? spreadsheetId : null;
  } catch (error) {
    console.log(error);
    return null;
  }
}
